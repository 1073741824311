import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import Enga7Page from "~/components/Enga/Enga7Page";

export default function () {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Construire des biens qui font du bien, c’est favoriser les mobilités douces en intégrant dans toutes nos résidences des locaux vélo adaptés et sécurisés ainsi que des stations de gonflage et de réparation.",
    },
  };

  return (
    <>
      <Title>Engagement Cogedim - Le respect de la biodiversité</Title>
      <MetaTags tags={[metaDesc]} />
      <Enga7Page />
    </>
  );
}
