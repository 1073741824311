import FirstScreen from "~/components/Enga/Components/FirstScreen";
import AutopromoContainer from "~/components/Enga/Components/AutopromoContainer";
import imgProxy from "~/utils/imgproxy";
import { urlRs } from "~/utils/url";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";

import "./EngaPages.css";
import "./EngaSinglePage.css";

import IconArrowForward from "~/img/icons/arrow_forward.svg";
import IconPlayArrow from "~/img/icons/play_arrow.svg";
import GtmVars from "../shared/Trackers/GtmVars";

export default function Enga7Page() {
  const gtmValues = () => {
    return {
      "all.pageType": "other",
      "all.mainCategory": "Engagement 7",
      "all.category": "Non défini",
      "all.subCategory": "Non défini",
      "all.subsubCategory": "Non défini",
      "all.subsubsubCategory": "Non défini",
      template: "nos-engagements",
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <article class="page-engagement page-engagement-7">
        <FirstScreen
          visual="/images/engagements/page-engagement-7-visual.jpg"
          visualMobile="/images/engagements/page-engagement-7-visual-mobile.jpg"
          title="Le respect de la biodiversité"
          prevLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/mobilite.html"
          nextLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/qualite-et-perennite-des-constructions.html"
        />

        <EngaDetails />
        <section class="engagement-rows">
          <h2>Les preuves de nos engagements</h2>
          <EngaRow1 />
        </section>
        <VideoExcerpt />

        <section class="next-nav">
          <a
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/qualite-et-perennite-des-constructions.html",
            )}
            class="btn"
          >
            Voir l'engagement suivant{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </section>
      </article>

      <AutopromoContainer />
    </>
  );
}

function EngaDetails() {
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <section class="engagement-details">
        <div class="introduction">
          <div class="tags">
            <span>Pour votre bien être</span>
          </div>
          <p>
            Construire des biens qui font du bien, c’est oeuvrer pour une
            meilleure réintégration de la biodiversité, en développant des
            projets où 100% des espaces extérieurs sont éco-aménagés.
          </p>
        </div>

        <div class="media-part">
          <div class="visual">
            <img
              fetchpriority="high"
              src={imgProxy(
                "/images/engagements/page-engagement-7-video.jpg",
                `size:690:442/resizing_type:fill`,
              )}
              alt=""
              height="240"
              width="375"
              loading="lazy"
            />
            <button
              class="btn btn-icon"
              onClick={() => {
                setMediaOverlay(
                  "embed",
                  `<iframe height="100%" width="100%" src="https://www.youtube.com/embed/5ujW5bl9McI" allowfullscreen />`,
                );
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconPlayArrow />
              </i>
            </button>
          </div>
          <div class="video-details">
            <blockquote class="alone">
              <p>
                À Vitrolles, la réserve des Salins du Lion, en bordure de
                l’étang de Berre, est réputée par sa richesse ornithologique.
              </p>
            </blockquote>
          </div>
        </div>
      </section>
    </>
  );
}

function EngaRow1() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Favoriser la biodiversité</h3>
          <p>
            Sur tous les nouveaux projets, Cogedim fait intervenir
            systématiquement un écologue dont la mission est de dresser un
            diagnostic écologique, et d’émettre des recommandations sur le plan
            de masse et le traitement des espaces extérieurs.
          </p>
          <p>
            Il intègre au mieux tous les enjeux de la biodiversité&nbsp;:
            gestion de l’eau, adaptation des végétaux, préservation de la faune
            et de la flore.
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-7-visuel1.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}

function VideoExcerpt() {
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <section class="video-excerpt">
        <div class="video-row">
          <div class="visual">
            <img
              src={imgProxy(
                "/images/engagements/page-engagement-7-excerpt-video.jpg",
                `size:675:480/resizing_type:fill`,
              )}
              height="267"
              width="375"
              alt=""
            />
            <button
              class="btn btn-icon"
              onClick={() => {
                setMediaOverlay(
                  "embed",
                  `<iframe height="100%" width="100%" src="https://www.youtube.com/embed/h07eTnM3QFw" allowfullscreen />`,
                );
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconPlayArrow />
              </i>
            </button>
          </div>
          <div class="video-details">
            <h3>Notre web-série “Tous engagés !”</h3>
            <h4>Promouvoir le bien être</h4>
            <p>Découvrez Issy Coeur de Ville, connecté par nature.</p>
          </div>
        </div>
      </section>
    </>
  );
}
